// settings
@import "settings/mixins";
@import "settings/colors";
@import "settings/typography";
@import "settings/variables";

.cpbulba-mediahub {
    background: $background-color;
    padding: 0 2rem 3rem;
    @include media("tablet", max) {
        padding: 0 1rem 2rem;
    }
    .wrapper {
        max-width: 1280px;
        margin: auto;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
        .heading {
            margin: 0;
            a {
                color: $gradient-color1;
            }
        }
        .button {
            font-size: .85rem;
            display: inline-block;
            color: $gradient-color1;
            border: 1px solid $gradient-color1;
            padding: .5rem;
            border-radius: .25rem;
            &:hover {
                background: rgba($gradient-color1, 0.1);
            }
        }
    }
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            background-color: $acqua;
            background-size: cover;
            background-position: center;
            display: flex;
            a{
                position: relative;
                width: 100%;
                min-height: 280px;
                display: flex;
                align-items: flex-end;
                background: rgba($text-color, .75);
                &:hover{
                    background: rgba($gradient-color1, .85);
                    &:before{
                        color: $acqua;
                    }
                    .content{
                        color: $acqua;
                        .heading{
                            color: $acqua;
                        }
                        img{
                            mix-blend-mode: overlay;
                            opacity: .75;
                        }
                    }
                }
            }
            .content{
                padding: 4em 1.75em 1.75em;
                color: #fff;
                .heading{
                    color: #fff;
                    margin-bottom: .5em;
                }
                img{
                    max-height: 3em;
                    border-radius: .25rem;
                    margin-bottom: .5em;
                    background: none !important;
                }
            }
            &.video{
                a{
                    &:before{
                        content: '\f408';
                        font-family: $zmdi;
                        color: rgba(#fff, .8);
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 5em;
                        line-height: 1;
                    }
                }
            }
        }
    }
    /* GRID SUPPORTED */
    @supports (display: grid) {
        ul{
            display: grid;
            grid-gap: 1rem;
            @include media("tablet", min){
                grid-template-rows: repeat(4, 1fr);
                grid-template-columns: repeat(6, 1fr);
                &>*{
                    grid-row: span 2;
                    grid-column: span 2;
                    &:nth-child(1){
                        grid-row: span 4;
                        grid-column: span 2;
                    }
                    &:nth-child(4){
                        grid-row: span 2;
                        grid-column: span 4;
                    }
                }
            }
            @include media("tablet", max){
                grid-template-rows: repeat(6, 1fr);
                grid-template-columns: repeat(6, 1fr);
                &>*{
                    grid-row: span 2;
                    grid-column: span 3;
                    &:nth-child(1){
                        grid-row: span 2;
                        grid-column: span 6;
                    }
                    &:nth-child(4){
                        grid-row: span 2;
                        grid-column: span 6;
                    }
                }
            }
            @include media("phone", max){
                grid-template-rows: repeat(4, 1fr);
                grid-template-columns: repeat(1, 1fr);
                &>*{
                    grid-row: span 4;
                    grid-column: span 1;
                    &:nth-child(1){
                        grid-row: span 4;
                        grid-column: span 1;
                    }
                    &:nth-child(4){
                        grid-row: span 4;
                        grid-column: span 1;
                    }
                }
            }
        }
    }
    /* GRID NOT SUPPORTED */
    @mixin gridNotSupported{
        ul{
            position: relative;
            @media (min-width: #{map-get($breakpoints, 'tablet-small')}){
                margin: -.5rem;
                display: flex;
                flex-wrap: wrap;
                &>*{
                    margin: .5rem;
                    width: calc(50% - 1rem);
                }
            }
            @media (max-width: #{map-get($breakpoints, 'tablet-small')}){
                &>*{
                    &:not(:last-child){
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
    /* IE ONLY */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
        @include gridNotSupported;
    }
    /* OTHER BROWSERS */
    @supports not (display: grid) {
        @include gridNotSupported;
    }
}